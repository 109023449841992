.profile-pic {
	max-width: 100%;
	display: block;
	border-radius: 50%;
	-webkit-border-radius: 50%;
}
.profile-img-edit {
	position: relative;
	display: inline-block;
	height: 150px;
	width: 150px;
}
.file-upload {
	display: none;
}
.p-image {
	position: absolute;
	top: auto;
	right: 8px;
	bottom: 10px;
	transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
	background: var(--#{$variable-prefix}primary);
	color: $white;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	line-height: 28px;
	text-align: center;
	font-size: 12px;
	cursor: pointer;
}
.upload-button {
	font-size: 1.5em;
}
.iq-edit-profile {
	.nav-link {
		padding: 20px 15px;
		border-radius: 0;
		color: var(--iq-body-text);
		text-align: center;
		font-size: 16px;
		border-left: 1px solid var(--iq-light-primary);
	}
}
.iq-edit-profile1 {
	.nav-link {
		padding: 5px 15px;
		border-radius: 0;
		color: var(--iq-body-text);
		text-align: center;
		font-size: 16px;
		border-left: 1px solid var(--iq-light-primary);
	}
}
.iq-edit-profile.nav-pills {
	.nav-link.active {
		color: $white;
		background: var(--#{$variable-prefix}primary);
	}
	.show {
		>.nav-link {
			color: $white;
			background: var(--#{$variable-prefix}primary);
		}
	}
}
ul.iq-edit-profile {
	li {
		&:first-child {
			a {
				border-radius: 5px 0 0 5px;
				border-left: none;
			}
		}
		&:last-child {
			a {
				border-radius: 0 5px 5px 0;
			}
		}
	}
}

.icon-button__badge {
	position: absolute;
	top: 14px;
	right: 5px;
	width: 20px;
	height: 20px;
	background: red;
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	font-size: 10px;
  }

  .card_read_background_color {
	background: rgb(245, 245, 245);
  }
  .video-img-btn{
	position: absolute;
  }
  @media only screen and (min-width: 900px) {
	.video-img-btn{
		left: 39%;
		top: 29%;
	}
  }
  @media only screen and (min-width: 750px) and (max-width: 900px) {
	.video-img-btn{
		left: 39%;
		top: 29%;
	}
  }
  @media only screen and (min-width: 350px) and (max-width: 750px) {
	.video-img-btn{
		left: 43%;
		top: 35%;
	}
  }
