.feather-icon {
	a {
		color: $secondary;
		font-weight: 300;

		&:hover {
			color: var(--#{$variable-prefix}primary);
		}
	}
}

.message-icon {
	a {
		color: $secondary;
		font-weight: 300;

		&:hover {
			color: var(--#{$variable-prefix}primary);
		}
	}
}

.cursor-pointer {
	cursor: pointer;
}