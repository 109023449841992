@charset "UTF-8";

:root {
  --color-white: #FFFFFF;
  --color-dark-1: #140342;
  --color-light-1: #4F547B;
  --color-light-5: #EDEDED;
  --color-light-6: #F5F7FE;
  --color-light-8: #DDDDDD;
  --color-purple-1: #3381a2;
  --color-orange-1: #E8543E;
  --color-red-2: #FCE5E2;
}

.masthead.-type-1 {
  z-index: 1;
  position: relative;
  padding: 140px 0 160px 0;
  padding: 140px 0 320px 0;
}

@media (max-width: 991px) {
  .masthead.-type-1 {
    padding-top: 140px;
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-1 {
    padding-bottom: 80px;
    padding-bottom: 160px;
  }
}

.masthead.-type-1 .container {
  max-width: 1530px;
  padding: 0 15px;
}

.masthead.-type-1 .masthead__bg {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-dark-1);
}

.masthead.-type-1 .masthead__bg img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}

.masthead.-type-1 .masthead__title {
  font-size: 55px;
  line-height: 80px;
  font-weight: 700;
  color: white;
}

@media (max-width: 991px) {
  .masthead.-type-1 .masthead__title {
    font-size: 50px;
    line-height: 1.3;
  }
  .masthead.-type-1 .masthead__title br {
    display: none;
  }
}

@media (max-width: 767px) {
  .masthead.-type-1 .masthead__title {
    font-size: 40px;
  }
}

@media (max-width: 575px) {
  .masthead.-type-1 .masthead__title {
    font-size: 30px;
  }
}

.masthead.-type-1 .masthead__text {
  margin-top: 13px;
  font-size: 17px;
  line-height: 36px;
  color: white;
}

@media (max-width: 991px) {
  .masthead.-type-1 .masthead__text {
    font-size: 16px;
    line-height: 1.6;
  }
  .masthead.-type-1 .masthead__text br {
    display: none;
  }
}

@media (max-width: 575px) {
  .masthead.-type-1 .masthead__text {
    margin-top: 10px;
    font-size: 15px;
  }
}

.masthead.-type-1 .masthead__buttons {
  padding-top: 27px;
}

@media (max-width: 767px) {
  .masthead.-type-1 .masthead__buttons {
    padding-top: 15px;
  }
  .masthead.-type-1 .masthead__buttons a {
    width: 100%;
  }
}

.masthead.-type-1 .masthead-image {
  display: grid;
  gap: 66px;
  grid-template-columns: 0.6fr 0.4fr;
  padding-left: 80px;
}

@media (max-width: 991px) {
  .masthead.-type-1 .masthead-image {
    padding-left: 0;
    grid-template-columns: 1fr 1fr;
    gap: 40px 24px;
    width: 85%;
  }
}

@media (max-width: 767px) {
  .masthead.-type-1 .masthead-image {
    width: 100%;
  }
}

.masthead.-type-1 .masthead-image > * {
  position: relative;
}

.masthead.-type-1 .masthead-image__el1 {
  grid-row: span 2;
  padding-top: 48px;
}

.masthead.-type-1 .masthead-image__el1 > div {
  position: relative;
  top: -54px;
  left: -132px;
}

.masthead.-type-1 .masthead-image__el2 {
  display: flex;
  justify-content: flex-end;
}

.masthead.-type-1 .masthead-image__el2 > div {
  position: absolute;
  bottom: -52px;
  right: 112px;
}

.masthead.-type-1 .masthead-image__el3 {
  display: flex;
  justify-content: flex-end;
}

.masthead.-type-1 .masthead-image__el3 > div {
  position: absolute;
  bottom: -50px;
  right: 15px;
}

.masthead.-type-1 .masthead-info {
  padding-top: 85px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .masthead.-type-1 .masthead-info {
    padding-top: 30px;
  }
}

.masthead.-type-1 .masthead-info__item {
  width: auto;
}

.masthead.-type-2 {
  position: relative;
  z-index: 0;
  margin-top: 91px;
  padding: 122px 0;
}

@media (max-width: 991px) {
  .masthead.-type-2 {
    padding: 100px 0;
  }
}

@media (max-width: 767px) {
  .masthead.-type-2 {
    padding: 60px 0;
  }
}

.masthead.-type-2 .row {
  padding: 0 60px;
}

@media (max-width: 991px) {
  .masthead.-type-2 .row {
    padding: 0 20px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-2 .row {
    padding: 0;
  }
}

.masthead.-type-2 .masthead__bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 60px;
  right: 60px;
  z-index: -1;
  height: 100%;
  border-radius: 16px;
}

@media (max-width: 991px) {
  .masthead.-type-2 .masthead__bg {
    left: 30px;
    right: 30px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-2 .masthead__bg {
    left: 0;
    right: 0;
    border-radius: 0;
  }
}

.masthead.-type-2 .masthead__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: var(--color-dark-1);
  opacity: 0.7;
}

.masthead.-type-2 .masthead__bg img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}

.masthead.-type-2 .masthead__title {
  text-transform: capitalize;
  font-size: 50px;
  font-weight: 700;
  line-height: 70px;
}

@media (max-width: 991px) {
  .masthead.-type-2 .masthead__title {
    font-size: 50px;
    line-height: 1.3;
  }
  .masthead.-type-2 .masthead__title br {
    display: none;
  }
}

@media (max-width: 767px) {
  .masthead.-type-2 .masthead__title {
    font-size: 40px;
  }
}

@media (max-width: 575px) {
  .masthead.-type-2 .masthead__title {
    font-size: 30px;
  }
}

.masthead.-type-2 .masthead__button {
  display: inline-block;
}

.masthead.-type-2 .masthead__button a {
  font-weight: 500;
}

.masthead.-type-2 .masthead-form {
  padding: 30px;
  padding-bottom: 38px;
  background-color: white;
  border-radius: 16px;
}

.masthead.-type-2 .masthead-form .masthead-form__group + .masthead-form__group {
  margin-top: 30px;
}

.masthead.-type-2 .masthead-form__group {
  display: flex;
  flex-direction: column;
}

.masthead.-type-2 .masthead-form__group label {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  color: var(--color-dark-1);
}

.masthead.-type-2 .masthead-form__group input {
  border: 1px solid var(--color-light-8);
  padding: 15px 22px;
  border-radius: 8px;
}

.masthead.-type-2 .masthead-form__group button {
  width: 100%;
  height: 60px;
}

.masthead.-type-2 .masthead-form__desc {
  margin-top: 17px;
  font-size: 13px;
  line-height: 22px;
}

.masthead.-type-3 {
  position: relative;
  z-index: 0;
  /* margin-top: 70px; */
  padding-top: 50px;
  padding-bottom: 60px;
}

.bg-red-2 {
  background-color: var(--color-red-2);
}

.bg-light-6 {
  background-color: var(--color-light-6);
}

.text-16 {
  font-size: var(--text-16) !important;
}

.size-50 {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.max-w-250 {
  max-width: 250px;
}

.bg-white {
  background-color: var(--color-white);
}

.rounded-8 {
  border-radius: 8px !important;
}

.rounded-full {
  border-radius: 100% !important;
}

.justify-center {
  justify-content: center !important;
}

@media (max-width: 991px) {
  .masthead.-type-3 {
    padding-top: 80px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-3 {
    padding-top: 40px;
  }
}

.masthead.-type-3 .masthead-image {
  position: relative;
  margin-top: -40px;
}

@media (max-width: 991px) {
  .masthead.-type-3 .masthead-image {
    padding-left: 0;
    grid-template-columns: 1fr 1fr;
    gap: 40px 24px;
    width: 85%;
  }
}

@media (max-width: 767px) {
  .masthead.-type-3 .masthead-image {
    width: 100%;
  }
}

.masthead.-type-3 .masthead-image__img1 img {
  max-width: unset;
  width: 100%;
}

.masthead.-type-3 .masthead-image__shape > img {
  position: absolute;
  top: -22vw;
  right: -30vw;
  z-index: -1;
  width: 50vw;
}

.masthead.-type-3 .masthead-image__el1 {
  position: absolute;
  bottom: 198px;
  right: -195px;
  width: 234px;
}

.masthead.-type-3 .masthead-image__el2 {
  position: absolute;
  bottom: 0;
  left: 35px;
}

.masthead.-type-3 .masthead-search__form {
  position: relative;
  width: 600px;
}

@media (max-width: 767px) {
  .masthead.-type-3 .masthead-search__form {
    width: 100%;
  }
}

.masthead.-type-3 .masthead-search__form input {
  width: 100%;
  height: 80px;
  border-radius: 100px;
  box-shadow: 0px 6px 15px 0px #404F680D;
  border: 1px solid #ECEDF2;
  padding: 0 40px;
}

@media (max-width: 767px) {
  .masthead.-type-3 .masthead-search__form input {
    height: 64px;
    padding: 0 30px;
    padding-right: 60px;
  }
}

.masthead.-type-3 .masthead-search__form input::-moz-placeholder {
  color: var(--color-light-1);
}

.masthead.-type-3 .masthead-search__form input:-ms-input-placeholder {
  color: var(--color-light-1);
}

.masthead.-type-3 .masthead-search__form input::placeholder {
  color: var(--color-light-1);
}

.masthead.-type-3 .masthead-search__form .button {
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  border-radius: 100%;
}

@media (max-width: 767px) {
  .masthead.-type-3 .masthead-search__form .button {
    width: 44px;
  }
}

.masthead.-type-3 .masthead__title {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 50px;
  line-height: 70px;
}

@media (max-width: 1399px) {
  .masthead.-type-3 .masthead__title {
    font-size: 50px;
    line-height: 1.3;
  }
  .masthead.-type-3 .masthead__title br {
    display: none;
  }
}

@media (max-width: 991px) {
  .masthead.-type-3 .masthead__title {
    font-size: 45px;
  }
  .masthead.-type-3 .masthead__title br {
    display: block;
  }
}

@media (max-width: 767px) {
  .masthead.-type-3 .masthead__title {
    font-size: 40px;
  }
  .masthead.-type-3 .masthead__title br {
    display: none;
  }
}

@media (max-width: 575px) {
  .masthead.-type-3 .masthead__title {
    font-size: 35px;
  }
}

.masthead.-type-4 {
  position: relative;
  z-index: 0;
  margin-top: 91px;
  padding-top: 102px;
}

@media (max-width: 991px) {
  .masthead.-type-4 {
    padding-top: 80px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-4 {
    padding-top: 40px;
  }
}

.masthead.-type-4 .masthead__image img {
  max-width: unset;
  width: 52vw;
}

@media (max-width: 991px) {
  .masthead.-type-4 .masthead__image img {
    width: 100%;
  }
}

.masthead.-type-4 .masthead__content {
  padding-bottom: 100px;
}

@media (max-width: 991px) {
  .masthead.-type-4 .masthead__content {
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-4 .masthead__content {
    padding-bottom: 40px;
  }
}

.masthead.-type-4 .masthead__title {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 45px;
  line-height: 80px;
}

@media (min-width: 1536px) {
  .masthead.-type-4 .masthead__title {
    font-size: 54px;
    line-height: 1.3;
  }
  .masthead.-type-4 .masthead__title br {
    display: none;
  }
}
@media (max-width: 1399px) {
  .masthead.-type-4 .masthead__title {
    font-size: 45px;
    line-height: 1.3;
  }
  .masthead.-type-4 .masthead__title br {
    display: none;
  }
}

@media (max-width: 991px) {
  .masthead.-type-4 .masthead__title {
    font-size: 45px;
  }
  .masthead.-type-4 .masthead__title br {
    display: block;
  }
}

@media (max-width: 767px) {
  .masthead.-type-4 .masthead__title {
    font-size: 40px;
  }
  .masthead.-type-4 .masthead__title br {
    display: none;
  }
}

@media (max-width: 575px) {
  .masthead.-type-4 .masthead__title {
    font-size: 35px;
  }
}

.masthead.-type-5 {
  position: relative;
  z-index: 0;
  padding-top: 180px;
  padding-bottom: 80px;
}

@media (max-width: 991px) {
  .masthead.-type-5 {
    padding-top: 160px;
  }
}

.masthead.-type-5 .container {
  max-width: 1530px;
  padding: 0 15px;
}

.masthead.-type-5 .masthead__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.masthead.-type-5 .masthead__bg img {
  height: 100%;
}

@media (max-width: 767px) {
  .masthead.-type-5 .masthead__bg img {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.masthead.-type-5 .masthead__container {
  padding-left: 210px;
  padding-right: 60px;
}

@media (max-width: 991px) {
  .masthead.-type-5 .masthead__container {
    padding-left: 60px;
  }
}

@media (max-width: 575px) {
  .masthead.-type-5 .masthead__container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.masthead.-type-5 .masthead__image img {
  width: 100%;
}

.masthead.-type-5 .masthead__title {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 55px;
  line-height: 80px;
}

@media (max-width: 1399px) {
  .masthead.-type-5 .masthead__title {
    font-size: 50px;
    line-height: 1.3;
  }
  .masthead.-type-5 .masthead__title br {
    display: none;
  }
}

@media (max-width: 991px) {
  .masthead.-type-5 .masthead__title {
    font-size: 45px;
  }
  .masthead.-type-5 .masthead__title br {
    display: block;
  }
}

@media (max-width: 767px) {
  .masthead.-type-5 .masthead__title {
    font-size: 40px;
  }
  .masthead.-type-5 .masthead__title br {
    display: none;
  }
}

@media (max-width: 575px) {
  .masthead.-type-5 .masthead__title {
    font-size: 35px;
  }
}

.masthead.-type-6 {
  position: relative;
  z-index: 0;
  padding-top: 140px;
  padding-bottom: 50px;
}

@media (max-width: 991px) {
  .masthead.-type-6 {
    padding-top: 120px;
  }
}

.masthead.-type-6 .masthead__bg > img {
  position: absolute;
  top: 0;
}

.masthead.-type-6 .masthead__bg > img:nth-child(1) {
  left: 0;
}

.masthead.-type-6 .masthead__bg > img:nth-child(2) {
  right: 0;
}

.masthead.-type-6 .masthead__bg > img:nth-child(3) {
  left: 0;
  width: 100%;
}

.masthead.-type-6 .masthead__image {
  margin-right: -40px;
}

.masthead.-type-6 .masthead__image img {
  width: 100%;
}

.masthead.-type-6 .masthead__title {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 55px;
  line-height: 1.2;
}

@media (max-width: 1399px) {
  .masthead.-type-6 .masthead__title {
    font-size: 50px;
  }
  .masthead.-type-6 .masthead__title br {
    display: none;
  }
}

@media (max-width: 991px) {
  .masthead.-type-6 .masthead__title {
    font-size: 45px;
  }
  .masthead.-type-6 .masthead__title br {
    display: block;
  }
}

@media (max-width: 767px) {
  .masthead.-type-6 .masthead__title {
    font-size: 40px;
  }
  .masthead.-type-6 .masthead__title br {
    display: none;
  }
}

@media (max-width: 575px) {
  .masthead.-type-6 .masthead__title {
    font-size: 35px;
  }
}

.masthead.-type-7 {
  position: relative;
  z-index: 0;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 120px;
}

@media (max-width: 1700px) {
  .masthead.-type-7 {
    margin-top: 90px;
  }
}

@media (max-width: 991px) {
  .masthead.-type-7 {
    padding-top: 90px;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-7 {
    margin-top: 0;
  }
}

.masthead.-type-7 .masthead__bg {
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  height: 100%;
}

@media (max-width: 1700px) {
  .masthead.-type-7 .masthead__bg {
    top: 0;
    left: -15px;
    right: -15px;
    border-radius: 0 !important;
  }
}

.masthead.-type-7 .masthead__bg > img {
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.masthead.-type-7 .masthead__image img {
  width: 100%;
}

.masthead.-type-7 .masthead__title {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 55px;
  line-height: 1.2;
}

@media (max-width: 1399px) {
  .masthead.-type-7 .masthead__title {
    font-size: 50px;
  }
  .masthead.-type-7 .masthead__title br {
    display: none;
  }
}

@media (max-width: 991px) {
  .masthead.-type-7 .masthead__title {
    font-size: 45px;
  }
  .masthead.-type-7 .masthead__title br {
    display: block;
  }
}

@media (max-width: 767px) {
  .masthead.-type-7 .masthead__title {
    font-size: 40px;
  }
  .masthead.-type-7 .masthead__title br {
    display: none;
  }
}

@media (max-width: 575px) {
  .masthead.-type-7 .masthead__title {
    font-size: 35px;
  }
}

.masthead.-type-7 .masthead-image {
  position: relative;
}

.masthead.-type-7 .masthead-image__img1 > img {
  width: 100%;
}

.masthead.-type-7 .masthead-image .-el-1 {
  position: absolute;
  bottom: 1.6vw;
  right: 5vw;
}

@media (max-width: 575px) {
  .masthead.-type-7 .masthead-image .-el-1 {
    display: none;
  }
}

.masthead.-type-7 .masthead-image .-el-2 {
  position: absolute;
  top: 7vw;
  left: 0.5vw;
}

@media (max-width: 575px) {
  .masthead.-type-7 .masthead-image .-el-2 {
    display: none;
  }
}

.masthead.-type-7 .masthead-image .-el-3 {
  position: absolute;
  top: 9vw;
  right: 2.6vw;
}

@media (max-width: 575px) {
  .masthead.-type-7 .masthead-image .-el-3 {
    display: none;
  }
}

.masthead.-type-7 .masthead-form {
  position: relative;
}

.masthead.-type-7 .masthead-form__item {
  display: flex;
  align-items: center;
  height: 60px;
  flex: 1 0 auto;
}

.masthead.-type-7 .masthead-form__item + .masthead-form__item {
  border-left: 1px solid var(--color-light-5);
}

@media (max-width: 960px) {
  .masthead.-type-7 .masthead-form__item + .masthead-form__item {
    border-left: 0;
  }
}

.masthead.-type-7 .masthead-form__item input {
  max-width: 100px;
}

.masthead.-type-7 .masthead-form__button {
  height: 60px;
  width: 25%;
  flex: 1 0 auto;
}

@media (max-width: 1350px) {
  .masthead.-type-7 .masthead-form__button {
    width: 100%;
  }
}

.masthead.-type-7 .masthead-form__button button {
  width: 100%;
  min-width: 120px;
  height: 100%;
  border-radius: 16px;
}

.container {
  padding-top: 0;
  padding-bottom: 0;
}

.d-flex {
  display: flex !important;
}

.y-gap-30 {
  margin-top: -0px;
  margin-bottom: -0px;
}

.y-gap-30 > * {
  padding-top: 15px;
  padding-bottom: 15px;
}
.md\:y-gap-30 {
  margin-top: -15px;
  margin-bottom: -15px;
}
.md\:y-gap-30 > * {
  padding-top: 15px;
  padding-bottom: 15px;
}
.sm\:y-gap-30 {
  margin-top: -15px;
  margin-bottom: -15px;
}
.sm\:y-gap-30 > * {
  padding-top: 15px;
  padding-bottom: 15px;
}

.text-orange-1 {
  color: var(--color-orange-1) !important;
}

.text-purple-1 {
  color: var(--color-purple-1) !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.img-el__side {
  position: absolute;
  top: -20px;
  left: -20px;
}

.bg-dark-1 {
  background-color: var(--color-dark-1);
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.img-el.-w-260 {
  width: 260px;
}

.shadow-4 {
  box-shadow: 0px 1px 4px 0px #14034212;
}

@media (max-width: 575px) {
  .composition.-type-5 .-el-4 {
    position: relative;
    bottom: unset;
    left: unset;
    margin-top: 30px;
  }
  .composition.-type-5 .-el-4 .-w-260 {
    width: 100%;
  }
}

.lh-1 {
  line-height: 1 !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.items-center {
  align-items: center !important;
}

.relative {
  position: relative;
}

.z-5 {
  z-index: 5;
}

@media (max-width: 1199px) {
  .xl\:justify-start {
    justify-content: flex-start !important;
  }
  .xl\:justify-end {
    justify-content: flex-end !important;
  }
  .xl\:justify-center {
    justify-content: center !important;
  }
  .xl\:justify-between {
    justify-content: space-between !important;
  }
  .xl\:items-start {
    align-items: flex-start !important;
  }
  .xl\:items-end {
    align-items: flex-end !important;
  }
  .xl\:items-center {
    align-items: center !important;
  }
}

@media (max-width: 991px) {
  .lg\:justify-start {
    justify-content: flex-start !important;
  }
  .lg\:justify-end {
    justify-content: flex-end !important;
  }
  .lg\:justify-center {
    justify-content: center !important;
  }
  .lg\:justify-between {
    justify-content: space-between !important;
  }
  .lg\:items-start {
    align-items: flex-start !important;
  }
  .lg\:items-end {
    align-items: flex-end !important;
  }
  .lg\:items-center {
    align-items: center !important;
  }
}

@media (max-width: 767px) {
  .md\:justify-start {
    justify-content: flex-start !important;
  }
  .md\:justify-end {
    justify-content: flex-end !important;
  }
  .md\:justify-center {
    justify-content: center !important;
  }
  .md\:justify-between {
    justify-content: space-between !important;
  }
  .md\:items-start {
    align-items: flex-start !important;
  }
  .md\:items-end {
    align-items: flex-end !important;
  }
  .md\:items-center {
    align-items: center !important;
  }
}

@media (max-width: 575px) {
  .sm\:justify-start {
    justify-content: flex-start !important;
  }
  .sm\:justify-end {
    justify-content: flex-end !important;
  }
  .sm\:justify-center {
    justify-content: center !important;
  }
  .sm\:justify-between {
    justify-content: space-between !important;
  }
  .sm\:items-start {
    align-items: flex-start !important;
  }
  .sm\:items-end {
    align-items: flex-end !important;
  }
  .sm\:items-center {
    align-items: center !important;
  }
}

@media (max-width: 1199px) {
  .xl\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .xl\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .xl\:pt-0 {
    padding-top: 0px !important;
  }
  .xl\:pb-0 {
    padding-bottom: 0px !important;
  }
  .xl\:pl-0 {
    padding-left: 0px !important;
  }
  .xl\:pr-0 {
    padding-right: 0px !important;
  }
  .xl\:mt-0 {
    margin-top: 0px !important;
  }
  .xl\:mb-0 {
    margin-bottom: 0px !important;
  }
  .xl\:ml-0 {
    margin-left: 0px !important;
  }
  .xl\:mr-0 {
    margin-right: 0px !important;
  }
  .xl\:py-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .xl\:px-3 {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .xl\:pt-3 {
    padding-top: 3px !important;
  }
  .xl\:pb-3 {
    padding-bottom: 3px !important;
  }
  .xl\:pl-3 {
    padding-left: 3px !important;
  }
  .xl\:pr-3 {
    padding-right: 3px !important;
  }
  .xl\:mt-3 {
    margin-top: 3px !important;
  }
  .xl\:mb-3 {
    margin-bottom: 3px !important;
  }
  .xl\:ml-3 {
    margin-left: 3px !important;
  }
  .xl\:mr-3 {
    margin-right: 3px !important;
  }
  .xl\:py-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .xl\:px-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .xl\:pt-5 {
    padding-top: 5px !important;
  }
  .xl\:pb-5 {
    padding-bottom: 5px !important;
  }
  .xl\:pl-5 {
    padding-left: 5px !important;
  }
  .xl\:pr-5 {
    padding-right: 5px !important;
  }
  .xl\:mt-5 {
    margin-top: 5px !important;
  }
  .xl\:mb-5 {
    margin-bottom: 5px !important;
  }
  .xl\:ml-5 {
    margin-left: 5px !important;
  }
  .xl\:mr-5 {
    margin-right: 5px !important;
  }
  .xl\:py-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .xl\:px-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .xl\:pt-8 {
    padding-top: 8px !important;
  }
  .xl\:pb-8 {
    padding-bottom: 8px !important;
  }
  .xl\:pl-8 {
    padding-left: 8px !important;
  }
  .xl\:pr-8 {
    padding-right: 8px !important;
  }
  .xl\:mt-8 {
    margin-top: 8px !important;
  }
  .xl\:mb-8 {
    margin-bottom: 8px !important;
  }
  .xl\:ml-8 {
    margin-left: 8px !important;
  }
  .xl\:mr-8 {
    margin-right: 8px !important;
  }
  .xl\:py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .xl\:px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .xl\:pt-10 {
    padding-top: 10px !important;
  }
  .xl\:pb-10 {
    padding-bottom: 10px !important;
  }
  .xl\:pl-10 {
    padding-left: 10px !important;
  }
  .xl\:pr-10 {
    padding-right: 10px !important;
  }
  .xl\:mt-10 {
    margin-top: 10px !important;
  }
  .xl\:mb-10 {
    margin-bottom: 10px !important;
  }
  .xl\:ml-10 {
    margin-left: 10px !important;
  }
  .xl\:mr-10 {
    margin-right: 10px !important;
  }
  .xl\:py-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .xl\:px-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .xl\:pt-12 {
    padding-top: 12px !important;
  }
  .xl\:pb-12 {
    padding-bottom: 12px !important;
  }
  .xl\:pl-12 {
    padding-left: 12px !important;
  }
  .xl\:pr-12 {
    padding-right: 12px !important;
  }
  .xl\:mt-12 {
    margin-top: 12px !important;
  }
  .xl\:mb-12 {
    margin-bottom: 12px !important;
  }
  .xl\:ml-12 {
    margin-left: 12px !important;
  }
  .xl\:mr-12 {
    margin-right: 12px !important;
  }
  .xl\:py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .xl\:px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .xl\:pt-15 {
    padding-top: 15px !important;
  }
  .xl\:pb-15 {
    padding-bottom: 15px !important;
  }
  .xl\:pl-15 {
    padding-left: 15px !important;
  }
  .xl\:pr-15 {
    padding-right: 15px !important;
  }
  .xl\:mt-15 {
    margin-top: 15px !important;
  }
  .xl\:mb-15 {
    margin-bottom: 15px !important;
  }
  .xl\:ml-15 {
    margin-left: 15px !important;
  }
  .xl\:mr-15 {
    margin-right: 15px !important;
  }
  .xl\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .xl\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .xl\:pt-20 {
    padding-top: 20px !important;
  }
  .xl\:pb-20 {
    padding-bottom: 20px !important;
  }
  .xl\:pl-20 {
    padding-left: 20px !important;
  }
  .xl\:pr-20 {
    padding-right: 20px !important;
  }
  .xl\:mt-20 {
    margin-top: 20px !important;
  }
  .xl\:mb-20 {
    margin-bottom: 20px !important;
  }
  .xl\:ml-20 {
    margin-left: 20px !important;
  }
  .xl\:mr-20 {
    margin-right: 20px !important;
  }
  .xl\:py-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .xl\:px-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .xl\:pt-25 {
    padding-top: 25px !important;
  }
  .xl\:pb-25 {
    padding-bottom: 25px !important;
  }
  .xl\:pl-25 {
    padding-left: 25px !important;
  }
  .xl\:pr-25 {
    padding-right: 25px !important;
  }
  .xl\:mt-25 {
    margin-top: 25px !important;
  }
  .xl\:mb-25 {
    margin-bottom: 25px !important;
  }
  .xl\:ml-25 {
    margin-left: 25px !important;
  }
  .xl\:mr-25 {
    margin-right: 25px !important;
  }
  .xl\:py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .xl\:px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .xl\:pt-30 {
    padding-top: 30px !important;
  }
  .xl\:pb-30 {
    padding-bottom: 30px !important;
  }
  .xl\:pl-30 {
    padding-left: 30px !important;
  }
  .xl\:pr-30 {
    padding-right: 30px !important;
  }
  .xl\:mt-30 {
    margin-top: 30px !important;
  }
  .xl\:mb-30 {
    margin-bottom: 30px !important;
  }
  .xl\:ml-30 {
    margin-left: 30px !important;
  }
  .xl\:mr-30 {
    margin-right: 30px !important;
  }
  .xl\:py-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .xl\:px-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .xl\:pt-35 {
    padding-top: 35px !important;
  }
  .xl\:pb-35 {
    padding-bottom: 35px !important;
  }
  .xl\:pl-35 {
    padding-left: 35px !important;
  }
  .xl\:pr-35 {
    padding-right: 35px !important;
  }
  .xl\:mt-35 {
    margin-top: 35px !important;
  }
  .xl\:mb-35 {
    margin-bottom: 35px !important;
  }
  .xl\:ml-35 {
    margin-left: 35px !important;
  }
  .xl\:mr-35 {
    margin-right: 35px !important;
  }
  .xl\:py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .xl\:px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .xl\:pt-40 {
    padding-top: 40px !important;
  }
  .xl\:pb-40 {
    padding-bottom: 40px !important;
  }
  .xl\:pl-40 {
    padding-left: 40px !important;
  }
  .xl\:pr-40 {
    padding-right: 40px !important;
  }
  .xl\:mt-40 {
    margin-top: 40px !important;
  }
  .xl\:mb-40 {
    margin-bottom: 40px !important;
  }
  .xl\:ml-40 {
    margin-left: 40px !important;
  }
  .xl\:mr-40 {
    margin-right: 40px !important;
  }
  .xl\:py-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .xl\:px-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .xl\:pt-45 {
    padding-top: 45px !important;
  }
  .xl\:pb-45 {
    padding-bottom: 45px !important;
  }
  .xl\:pl-45 {
    padding-left: 45px !important;
  }
  .xl\:pr-45 {
    padding-right: 45px !important;
  }
  .xl\:mt-45 {
    margin-top: 45px !important;
  }
  .xl\:mb-45 {
    margin-bottom: 45px !important;
  }
  .xl\:ml-45 {
    margin-left: 45px !important;
  }
  .xl\:mr-45 {
    margin-right: 45px !important;
  }
  .xl\:py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .xl\:px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .xl\:pt-50 {
    padding-top: 50px !important;
  }
  .xl\:pb-50 {
    padding-bottom: 50px !important;
  }
  .xl\:pl-50 {
    padding-left: 50px !important;
  }
  .xl\:pr-50 {
    padding-right: 50px !important;
  }
  .xl\:mt-50 {
    margin-top: 50px !important;
  }
  .xl\:mb-50 {
    margin-bottom: 50px !important;
  }
  .xl\:ml-50 {
    margin-left: 50px !important;
  }
  .xl\:mr-50 {
    margin-right: 50px !important;
  }
  .xl\:py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .xl\:px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .xl\:pt-60 {
    padding-top: 60px !important;
  }
  .xl\:pb-60 {
    padding-bottom: 60px !important;
  }
  .xl\:pl-60 {
    padding-left: 60px !important;
  }
  .xl\:pr-60 {
    padding-right: 60px !important;
  }
  .xl\:mt-60 {
    margin-top: 60px !important;
  }
  .xl\:mb-60 {
    margin-bottom: 60px !important;
  }
  .xl\:ml-60 {
    margin-left: 60px !important;
  }
  .xl\:mr-60 {
    margin-right: 60px !important;
  }
  .xl\:py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .xl\:px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .xl\:pt-80 {
    padding-top: 80px !important;
  }
  .xl\:pb-80 {
    padding-bottom: 80px !important;
  }
  .xl\:pl-80 {
    padding-left: 80px !important;
  }
  .xl\:pr-80 {
    padding-right: 80px !important;
  }
  .xl\:mt-80 {
    margin-top: 80px !important;
  }
  .xl\:mb-80 {
    margin-bottom: 80px !important;
  }
  .xl\:ml-80 {
    margin-left: 80px !important;
  }
  .xl\:mr-80 {
    margin-right: 80px !important;
  }
  .xl\:py-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .xl\:px-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .xl\:pt-90 {
    padding-top: 90px !important;
  }
  .xl\:pb-90 {
    padding-bottom: 90px !important;
  }
  .xl\:pl-90 {
    padding-left: 90px !important;
  }
  .xl\:pr-90 {
    padding-right: 90px !important;
  }
  .xl\:mt-90 {
    margin-top: 90px !important;
  }
  .xl\:mb-90 {
    margin-bottom: 90px !important;
  }
  .xl\:ml-90 {
    margin-left: 90px !important;
  }
  .xl\:mr-90 {
    margin-right: 90px !important;
  }
}

.svg-waves {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 180px;
}

@media (max-width: 767px) {
  .svg-waves {
    height: 80px;
  }
}

.svg-waves__parallax > use {
  -webkit-animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
          animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.svg-waves__parallax > use:nth-child(1) {
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
  -webkit-animation-duration: 7s;
          animation-duration: 7s;
  fill: rgba(255, 255, 255, 0.7);
}

.svg-waves__parallax > use:nth-child(2) {
  -webkit-animation-delay: -3s;
          animation-delay: -3s;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
  fill: rgba(255, 255, 255, 0.5);
}

.svg-waves__parallax > use:nth-child(3) {
  -webkit-animation-delay: -4s;
          animation-delay: -4s;
  -webkit-animation-duration: 13s;
          animation-duration: 13s;
  fill: rgba(255, 255, 255, 0.3);
}

.svg-waves__parallax > use:nth-child(4) {
  -webkit-animation-delay: -5s;
          animation-delay: -5s;
  -webkit-animation-duration: 20s;
          animation-duration: 20s;
  fill: white;
}



