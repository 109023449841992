.live-customizer {
    box-shadow: $dropdown-box-shadow;
    border: 0;
}
.ri-pencil-line:before {
    display: block; 
    list-style: none;
    height: 2.5rem !important;
    width: 2.5rem !important;
    background: #fff;
    border-radius: 50rem;
    line-height: 2.5rem;
    text-align: center;
    color: var(--bs-primary);
    font-size: 1.125rem;
    margin: 0 0.938rem 0 0;
    cursor: pointer;
}
@media (min-width: 768px) {
    .live-customizer {
        width: 450px;
    }
    .theme-fs-lg{
        .live-customizer {
            width: 500px;
        }
    }
}