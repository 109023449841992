@each $color in $colors-name {
    .list-group-item-#{$color}{
        color: var(--#{$variable-prefix}#{$color}-shade-40);
        background-color: var(--#{$variable-prefix}#{$color}-tint-80);
        &.list-group-item-action{
            &:hover, &:focus{
                color: var(--#{$variable-prefix}#{$color}-shade-40);
                background-color: var(--#{$variable-prefix}#{$color}-tint-60);
            }
        }
    }
}
.list-group-item{
    &.active{
        background-color: var(--#{$variable-prefix}primary);
        border-color: var(--#{$variable-prefix}primary);
    }
}

.list-group-item-action:not(.active){
    &:hover, &:focus{
        background-color: transparent;
    }
    &:active{
        background-color: var(--#{$variable-prefix}primary-tint-80);
    }
}

.my-masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}
.my-masonry-grid_item {
  margin-bottom: 30px; /* space between items */
}